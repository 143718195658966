import React from 'react';
import WorkItem from './WorkItem';

const workItems = [
	{
		number: 1,
		imageSrc: 'img/svg/work/notes.svg',
		text: 'Ви лишаєте заявку або зв’язуєтесь по вказаних контактах на сайті'
	},
	{
		number: 2,
		imageSrc: 'img/svg/work/building.svg',
		text: 'Виїзд майстра на об’єкт'
	},
	{
		number: 3,
		imageSrc: 'img/svg/work/document.svg',
		text: 'Укладаємо договір'
	},
	{
		number: 4,
		imageSrc: 'img/svg/work/shopping.svg',
		text: 'Закупка матеріалів'
	},
	{
		number: 5,
		imageSrc: 'img/svg/work/paint.svg',
		text: 'Працюємо над об’єктом'
	},
	{
		number: 6,
		imageSrc: 'img/svg/work/key.svg',
		text: 'Здаємо готовий результат'
	}
];

const Work = () => {
	return (
		<section id='work' className='work'>
			<div className='work__container'>
				<div className='work__title title'>Як ми працюємо ?</div>
				<div className='work__items'>
					{workItems.map((item, index) => (
						<WorkItem
							key={index}
							number={item.number}
							imageSrc={item.imageSrc}
							text={item.text}
						/>
					))}
				</div>
			</div>
			<div className='work__underline divider divider-work'></div>
		</section>
	);
};

export default Work;
