import React from 'react';
import Advantage from './Advantage';

const advantagesItems = [
	{
		id: 1,
		icon: 'img/svg/advantages/stars.svg',
		text: 'Якісний ремонт'
	},
	{
		id: 2,
		icon: 'img/svg/advantages/photo.svg',
		text: 'Регулярні фото та відео звіти з об’єкта'
	},
	{
		id: 3,
		icon: 'img/svg/advantages/people.svg',
		text: 'Досвідчені фахівці'
	},
	{
		id: 4,
		icon: 'img/svg/advantages/house.svg',
		text: 'Відсутність головної болі для замовника'
	},
	{
		id: 5,
		icon: 'img/svg/advantages/diploma.svg',
		text: 'Гарантія на роботи один рік'
	},
	{
		id: 6,
		icon: 'img/svg/advantages/sport.svg',
		text: 'Допомого у виборі будівельних матеріалів'
	}
];

const Advantages = () => {
	return (
		<section id='advantages' className='advantages'>
			<div className='advantages__container'>
				<div className='advantages__body'>
					<div className='advantages__title title'>Наші переваги</div>
					<div className='advantages__items'>
						{advantagesItems.map((item) => (
							<Advantage
								key={item.id}
								imageSrc={item.icon}
								text={item.text}
							/>
						))}
					</div>
				</div>
				<div className='advantages__underline divider'></div>
			</div>
		</section>
	);
};

export default Advantages;
