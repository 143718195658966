import React from 'react';
import { Link } from 'react-scroll';

const HeaderItem = ({ targetId, text, onItemClick }) => {
	const handleClick = () => {
		onItemClick();
	};
	return (
		<li>
			<Link
				className='menu__link'
				to={targetId}
				smooth={true}
				duration={500}
				onClick={handleClick}
			>
				{text}
			</Link>
		</li>
	);
};

export default HeaderItem;
