import React from 'react';
import ScrollToTopButton from './ScrollToTopButton';

const About = () => {
	return (
		<section id='about' className='about'>
			<div className='about__container'>
				<div className='about__body'>
					<div className='about__image'>
						<img src='img/about.jpg' alt='' />
					</div>
					<div className='about__content'>
						<div className='about__title title'>Про нас</div>
						<div className='about__text text'>
							<p>
								Ми спеціалізуємось на внутрішній обробці
								приміщень різного ступеня складності,
								виробництві всіх видів ремонтних робіт від
								косметичного до елітних рівнів та реконструкції
								приміщень.
							</p>
							<p>
								Для цього маємо необхідну кількість досвідчених,
								висококласних, різнопрофільних фахівців, а також
								все необхідне обладнання та інструменти.
							</p>
						</div>
					</div>
				</div>
				<div className='about__underline divider'></div>
			</div>
			<ScrollToTopButton />
		</section>
	);
};

export default About;
