import React from 'react';

const Footer = () => {
	return (
		<footer id='footer' className='footer'>
			<div className='footer__container'>
				<div className='footer__items'>
					<div className='footer__item item-footer'>
						<div className='item-footer__icon'>
							<img src='img/svg/footer/time.svg' alt='Clock' />
						</div>
						<div className='item-footer__text'>
							Пн-Сб з 9:00 по 18:00
						</div>
					</div>
					<div className='footer__item item-footer'>
						<div className='item-footer__icon'>
							<img src='img/svg/footer/place.svg' alt='Place' />
						</div>

						<div className='item-footer__text'>
							<a
								href='https://goo.gl/maps/iDrGuPHFmtj4dznW8?coh=178573&entry=tt'
								target='_blank'
							>
								м. Київ
							</a>
						</div>
					</div>
					<div className='footer__item item-footer'>
						<div className='item-footer__icon'>
							<img src='img/svg/footer/phone.svg' alt='Phone' />
						</div>
						<div className='item-footer__text'>
							<a href='tel:+380956272771'>+3 8 (095) 627 27 71</a>
						</div>
					</div>
					<div className='footer__item item-footer'>
						<div className='item-footer__icon'>
							<img
								src='img/svg/footer/instagram.svg'
								alt='Instagram'
							/>
						</div>
						<div className='item-footer__text'>
							<a
								href='https://www.instagram.com/_dk_house/?igshid=MzRlODBiNWFlZA%3D%3D'
								target='_blank'
							>
								Instagram
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
