import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const collections = [
	{
		id: 1,
		name: 'ЖК Джек Хаус',
		photos: [
			{
				id: 1,
				title: 'Фото 2.1',
				imageSrc: 'img/gallery/2/1.jpg'
			},
			{
				id: 2,
				title: 'Фото 2.2',
				imageSrc: 'img/gallery/2/2.jpg'
			},
			{
				id: 3,
				title: 'Фото 2.3',
				imageSrc: 'img/gallery/2/3.jpg'
			},
			{
				id: 4,
				title: 'Фото 2.4',
				imageSrc: 'img/gallery/2/4.jpg'
			},
			{
				id: 5,
				title: 'Фото 2.5',
				imageSrc: 'img/gallery/2/5.jpg'
			},
			{
				id: 6,
				title: 'Фото 2.6',
				imageSrc: 'img/gallery/2/6.jpg'
			},
			{
				id: 7,
				title: 'Фото 2.7',
				imageSrc: 'img/gallery/2/7.jpg'
			},
			{
				id: 8,
				title: 'Фото 2.2',
				imageSrc: 'img/gallery/2/8.jpg'
			},
			{
				id: 9,
				title: 'Фото 2.9',
				imageSrc: 'img/gallery/2/9.jpg'
			},
			{
				id: 10,
				title: 'Фото 2.10',
				imageSrc: 'img/gallery/2/10.jpg'
			},
			{
				id: 11,
				title: 'Фото 2.11',
				imageSrc: 'img/gallery/2/11.jpg'
			},
			{
				id: 12,
				title: 'Фото 2.12',
				imageSrc: 'img/gallery/2/12.jpg'
			},
			{
				id: 13,
				title: 'Фото 2.13',
				imageSrc: 'img/gallery/2/13.jpg'
			}
		]
	},
	{
		id: 2,
		name: 'ЖК  Альтер Его',
		photos: [
			{
				id: 1,
				title: 'Фото 4.1',
				imageSrc: 'img/gallery/4/1.jpg'
			},
			{
				id: 2,
				title: 'Фото 4.2',
				imageSrc: 'img/gallery/4/2.jpg'
			},
			{
				id: 3,
				title: 'Фото 4.3',
				imageSrc: 'img/gallery/4/3.jpg'
			},
			{
				id: 4,
				title: 'Фото 4.4',
				imageSrc: 'img/gallery/4/4.jpg'
			},
			{
				id: 5,
				title: 'Фото 4.5',
				imageSrc: 'img/gallery/4/5.jpg'
			},
			{
				id: 6,
				title: 'Фото 4.6',
				imageSrc: 'img/gallery/4/6.jpg'
			},
			{
				id: 7,
				title: 'Фото 4.7',
				imageSrc: 'img/gallery/4/7.jpg'
			},
			{
				id: 8,
				title: 'Фото 4.4',
				imageSrc: 'img/gallery/4/8.jpg'
			},
			{
				id: 9,
				title: 'Фото 4.9',
				imageSrc: 'img/gallery/4/9.jpg'
			},
			{
				id: 10,
				title: 'Фото 4.10',
				imageSrc: 'img/gallery/4/10.jpg'
			},
			{
				id: 11,
				title: 'Фото 4.11',
				imageSrc: 'img/gallery/4/11.jpg'
			},
			{
				id: 12,
				title: 'Фото 4.12',
				imageSrc: 'img/gallery/4/12.jpg'
			},
			{
				id: 13,
				title: 'Фото 4.13',
				imageSrc: 'img/gallery/4/13.jpg'
			}
		]
	},
	{
		id: 3,
		name: 'ЖК Джек Хаус',
		photos: [
			{
				id: 1,
				title: 'Фото 1.1',
				imageSrc: 'img/gallery/1/1.jpg'
			},
			{
				id: 2,
				title: 'Фото 1.2',
				imageSrc: 'img/gallery/1/2.jpg'
			},
			{
				id: 3,
				title: 'Фото 1.3',
				imageSrc: 'img/gallery/1/3.jpg'
			},
			{
				id: 4,
				title: 'Фото 1.4',
				imageSrc: 'img/gallery/1/4.jpg'
			},
			{
				id: 5,
				title: 'Фото 1.5',
				imageSrc: 'img/gallery/1/5.jpg'
			},
			{
				id: 6,
				title: 'Фото 1.6',
				imageSrc: 'img/gallery/1/6.jpg'
			},
			{
				id: 7,
				title: 'Фото 1.7',
				imageSrc: 'img/gallery/1/7.jpg'
			},
			{
				id: 8,
				title: 'Фото 1.8',
				imageSrc: 'img/gallery/1/8.jpg'
			},
			{
				id: 9,
				title: 'Фото 1.9',
				imageSrc: 'img/gallery/1/9.jpg'
			},
			{
				id: 10,
				title: 'Фото 1.10',
				imageSrc: 'img/gallery/1/10.jpg'
			}
		]
	},
	{
		id: 4,
		name: 'ЖК Софія Резіденс',
		photos: [
			{
				id: 1,
				title: 'Фото 3.1',
				imageSrc: 'img/gallery/3/1.jpg'
			},
			{
				id: 2,
				title: 'Фото 3.2',
				imageSrc: 'img/gallery/3/2.jpg'
			},
			{
				id: 3,
				title: 'Фото 3.3',
				imageSrc: 'img/gallery/3/3.jpg'
			},
			{
				id: 4,
				title: 'Фото 3.4',
				imageSrc: 'img/gallery/3/4.jpg'
			},
			{
				id: 5,
				title: 'Фото 3.5',
				imageSrc: 'img/gallery/3/5.jpg'
			},
			{
				id: 6,
				title: 'Фото 3.6',
				imageSrc: 'img/gallery/3/6.jpg'
			},
			{
				id: 7,
				title: 'Фото 3.7',
				imageSrc: 'img/gallery/3/7.jpg'
			},
			{
				id: 8,
				title: 'Фото 3.8',
				imageSrc: 'img/gallery/3/8.jpg'
			}
		]
	}
];

const Portfolio = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
	const [currentCollectionIndex, setCurrentCollectionIndex] = useState(0);

	const openModal = (collectionIndex, photoIndex) => {
		setCurrentPhotoIndex(photoIndex);
		setCurrentCollectionIndex(collectionIndex);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const nextPhoto = () => {
		console.log(currentCollectionIndex);
		const currentCollection = collections[currentCollectionIndex];
		setCurrentPhotoIndex((prevIndex) =>
			prevIndex === currentCollection.photos.length - 1
				? 0
				: prevIndex + 1
		);
	};

	const prevPhoto = () => {
		const currentCollection = collections[currentCollectionIndex];
		setCurrentPhotoIndex((prevIndex) =>
			prevIndex === 0
				? currentCollection.photos.length - 1
				: prevIndex - 1
		);
	};

	useEffect(() => {
		if (modalIsOpen) {
			document.documentElement.classList.add('modal-open');
		} else {
			document.documentElement.classList.remove('modal-open');
		}
	}, [modalIsOpen]);

	const sliderSettings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: (
			<img
				src='img/svg/next.svg'
				alt='next'
				className='slick-next slick-arrow'
				onClick={nextPhoto}
			/>
		),
		prevArrow: (
			<img
				src='img/svg/prev.svg'
				alt='prev'
				className='slick-prev slick-arrow'
				onClick={prevPhoto}
			/>
		)
	};

	return (
		<section id='portfolio' className='portfolio'>
			<div className='portfolio__container'>
				<div className='portfolio__title title'>Портфоліо</div>
			</div>
			<div className='portfolio__body'>
				<div className='portfolio__items'>
					{collections.map((collection, index) => (
						<div
							key={collection.id}
							className='portfolio__item item-portfolio'
							onClick={() => openModal(index)}
						>
							<div className='item-portfolio__image item-portfolio__image-ibg'>
								<img
									src={collection.photos[0].imageSrc}
									alt={collection.name}
								/>
								<div className='item-portfolio__content'>
									<div className='item-portfolio__title'>
										{collection.name}
									</div>
									<button
										className='item-portfolio__button text'
										onClick={() => openModal(index, 0)}
									>
										Переглянути проект
									</button>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				contentLabel='Portfolio Modal'
				className='portfolio-modal'
				overlayClassName='portfolio-modal-overlay'
			>
				<Slider {...sliderSettings}>
					{collections[currentCollectionIndex].photos.map((photo) => (
						<div key={photo.id}>
							<img
								src={photo.imageSrc}
								alt={photo.title}
								className='modal-image'
							/>
						</div>
					))}
				</Slider>
				<button className='cancel-modal' onClick={closeModal}>
					<img src='img/svg/cancel.svg' alt='Cancel' />
				</button>
			</Modal>
		</section>
	);
};

export default Portfolio;
