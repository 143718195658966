import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
	const [isVisible, setIsVisible] = useState(false);

	const handleScroll = () => {
		const scrollY = window.scrollY;
		const windowHeight = window.innerHeight;
		const scrollThreshold = windowHeight * 0.8;

		setIsVisible(scrollY > scrollThreshold);
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div
			className={`scroll-to-top-button ${isVisible ? 'visible' : ''}`}
			onClick={scrollToTop}
		>
			<img src="img/svg/arrow-up.svg" alt="Arrow Up" />
		</div>
	);
};

export default ScrollToTopButton;
