import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import HeaderItem from './HeaderItem';

import { HiOutlineMenuAlt2 } from 'react-icons/hi';
import { RxCross1 } from 'react-icons/rx';

const headerItems = [
	{ id: 'hero', text: 'ГОЛОВНА' },
	{ id: 'about', text: 'ПРО НАС' },
	{ id: 'work', text: 'ЯК МИ ПРАЦЮЄМО' },
	{ id: 'portfolio', text: 'ПОРТФОЛІО' },
	{ id: 'cost', text: 'РОЗРАХУНОК ВАРТОСТІ' },
	{ id: 'footer', text: 'КОНТАКТИ' }
];

const Header = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleClick = () => {
		setIsMenuOpen((value) => !value);
	};

	const handleItemClick = () => {
		if (isMenuOpen) {
			setIsMenuOpen(false);
		}
	};

	useEffect(() => {
		if (isMenuOpen) {
			document.documentElement.classList.add('menu-open');
			document.documentElement.classList.add('lock');
		} else {
			document.documentElement.classList.remove('menu-open');
			document.documentElement.classList.remove('lock');
		}
	}, [isMenuOpen]);

	return (
		<div className='header'>
			<div className='header__container'>
				<Link to='/' className='header__logo'>
					<img src='logo.svg' alt='Logo' />
				</Link>
				<div className='header__menu menu'>
					{!isMenuOpen && (
						<HiOutlineMenuAlt2
							className='menu__icon'
							size={30}
							onClick={handleClick}
						/>
					)}
					<nav
						className={`menu__body ${
							isMenuOpen ? 'menu-open' : ''
						}`}
					>
						{isMenuOpen && (
							<RxCross1
								className='menu__icon'
								size={15}
								onClick={handleClick}
							/>
						)}
						<ul className='menu__list'>
							{headerItems.map((item, index) => (
								<HeaderItem
									key={index}
									targetId={item.id}
									text={item.text}
									onItemClick={handleItemClick}
								/>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default Header;
