import React from 'react';

const Advantage = ({ imageSrc, text }) => {
	return (
		<div className='advantages__item'>
			<div className='advantages__icon'>
				<img src={imageSrc} alt={imageSrc} />
			</div>
			<div className='advantages__text text text-advantages'>{text}</div>
		</div>
	);
};

export default Advantage;
