import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';

import axios from 'axios';

const options = ['Через тиждень', 'Через місяць', 'Через півроку'];

const Cost = () => {
	const [square, setSquare] = useState('');

	const [step, setStep] = useState(0);
	const [text, setText] = useState(options[0]);
	const [optionsVisible, setOptionsVisible] = useState(false);
	const [selectedOption, setSelectedOption] = useState('Ні');

	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [isValidName, setIsValidName] = useState(false);
	const [isValidPhone, setIsValidPhone] = useState(false);

	const handleSubmit = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/cost`, {
				square,
				text,
				selectedOption,
				name,
				phone
			})
			.then((response) => {
				if (response.data.success) {
					setSquare('');
					setName('');
					setPhone('');
					setIsValidName(false);
					setIsValidPhone(false);

					setStep((value) => value + 1);

					setTimeout(() => {
						setStep(0);
					}, 2000);
				} else {
					console.error(
						'Произошла ошибка при отправке данных в телеграм-бота'
					);
				}
			})
			.catch((error) => {
				console.error(
					'Произошла ошибка при отправке данных в серверную часть',
					error
				);
			});
	};

	const onBack = () => {
		setStep((value) => value - 1);
	};

	const onNext = () => {
		setStep((value) => value + 1);
	};

	const changeSquare = (event) => {
		const enteredValue = event.target.value;
		const regex = /^[0-9\b]+$/;

		if (enteredValue === '' || regex.test(enteredValue)) {
			setSquare(enteredValue);
		}
	};

	let bodyContent = (
		<>
			<div className='cost__title title'>Розрахунок вартості</div>
			<div className='cost__body'>
				<div className='cost__number number'>1</div>
				<div className='cost__item item-cost'>
					<div className='item-cost__icon item-cost__icon-1'>
						<img src='img/svg/cost/1.svg' alt='Icon' />
					</div>
					<div className='item-cost__text text'>
						Площа вашого об'єкту?
					</div>
					<input
						type='number'
						value={square}
						onChange={changeSquare}
						inputMode='numeric'
						className='item-cost__input item-cost__input-square text'
					/>
					<div className='item-cost__metr'>
						<sub className='text'>м</sub>2
					</div>
				</div>
			</div>
			<div className='cost__buttons'>
				<button
					disabled={step === 0}
					className={`cost__button cost__button-back text ${
						step === 0 ? 'unactive' : ''
					}`}
					onClick={onBack}
				>
					Назад
				</button>
				<button
					disabled={!square}
					className={`cost__button cost__button-back text ${
						!square ? 'unactive' : ''
					}`}
					onClick={onNext}
				>
					Далі
				</button>
			</div>
		</>
	);

	const handleArrowClick = () => {
		setOptionsVisible(!optionsVisible);
	};

	const handleOptionClick = (option) => {
		setText(option);
		setOptionsVisible(false);
	};

	if (step === 1) {
		bodyContent = (
			<>
				<div className='cost__title title'>Розрахунок вартості</div>
				<div className='cost__body'>
					<div className='cost__number number'>2</div>
					<div className='cost__item item-cost item-cost__small'>
						<div className='item-cost__select'>
							<div className='item-cost__icon item-cost__icon-2'>
								<img src='img/svg/cost/2.svg' alt='Icon' />
							</div>
							<div className='item-cost__text text'>
								Коли готові розпочати роботи?
							</div>
						</div>
						<div className='select' onClick={handleArrowClick}>
							<div className='select__body'>
								<div className='select__text text'>{text}</div>
								<div className='select__button'>
									<img
										src='img/svg/cost/arrow.svg'
										alt='Arrow'
										className={`select__arrow ${
											optionsVisible ? 'open' : ''
										}`}
									/>
								</div>
							</div>
							{optionsVisible && (
								<ul className='select__items'>
									{options.map((option, index) => (
										<li
											key={index}
											onClick={() =>
												handleOptionClick(option)
											}
											className='select__item text'
										>
											{option}
										</li>
									))}
								</ul>
							)}
						</div>
					</div>
				</div>
				<div className='cost__buttons'>
					<button
						disabled={step === 0}
						className={`cost__button cost__button-back text ${
							step === 0 ? 'unactive' : ''
						}`}
						onClick={onBack}
					>
						Назад
					</button>
					<button
						disabled={step === 3}
						className={`cost__button cost__button-back text ${
							step === 3 ? 'unactive' : ''
						}`}
						onClick={onNext}
					>
						Далі
					</button>
				</div>
			</>
		);
	}

	const handleOptionChange = (option) => {
		if (option !== selectedOption) {
			setSelectedOption(option);
		}
	};

	if (step === 2) {
		bodyContent = (
			<>
				<div className='cost__title title'>Розрахунок вартості</div>
				<div className='cost__body'>
					<div className='cost__number number'>3</div>
					<div className='cost__item item-cost'>
						<div className='item-cost__icon-3'>
							<img src='img/svg/cost/3.svg' alt='Icon' />
						</div>
						<div className='item-cost__text text'>
							Ви маєте дизайн-проект?
						</div>
						<label className='item-cost__checkboxes'>
							<div className='item-cost__checkbox checkbox'>
								<input
									type='radio'
									checked={selectedOption === 'Так'}
									onChange={() => handleOptionChange('Так')}
									className='checkbox__input'
								/>
								<div className='checkbox__text text'>Так</div>
							</div>
							<div className='item-cost__checkbox checkbox'>
								<input
									type='radio'
									checked={selectedOption === 'Ні'}
									onChange={() => handleOptionChange('Ні')}
									className='checkbox__input'
								/>
								<div className='checkbox__text text'>Ні</div>
							</div>
						</label>
					</div>
				</div>
				<div className='cost__buttons'>
					<button
						disabled={step === 0}
						className={`cost__button cost__button-back text ${
							step === 0 ? 'unactive' : ''
						}`}
						onClick={onBack}
					>
						Назад
					</button>
					<button
						disabled={step === 3}
						className={`cost__button cost__button-back text ${
							step === 3 ? 'unactive' : ''
						}`}
						onClick={onNext}
					>
						Далі
					</button>
				</div>
			</>
		);
	}

	const handleNameChange = (event) => {
		const newName = event.target.value;
		setName(newName);

		setIsValidName(newName.length > 1);
	};

	const handlePhoneChange = (value) => {
		setPhone(value);

		setIsValidPhone(value.length === 12);
	};

	if (step === 3) {
		bodyContent = (
			<div className='cost__item-center'>
				<div className='item-cost__content'>
					<div className='item-cost__title title'>Майже готово</div>
					<div className='item-cost__text-center text'>
						Для точного розрахунку залиште свої контактні дані
					</div>
				</div>
				<div className='item-cost__input-cost input-cost'>
					<span className='input-cost__icon'>
						<img src='img/svg/cost/person.svg' alt='Person' />
					</span>
					<input
						type='text'
						placeholder='Ваше ім`я'
						value={name}
						onChange={handleNameChange}
						className='input-cost__input'
					/>
					{isValidName ? (
						<span
							className={`input-cost__validation-icon ${
								name.length === 0 ? 'hidden' : ''
							}`}
						>
							<img src='img/svg/cost/true.svg' alt='True' />
						</span>
					) : (
						<span
							className={`input-cost__validation-icon ${
								name.length === 0 ? 'hidden' : ''
							}`}
						>
							<img src='img/svg/cost/false.svg' alt='False' />
						</span>
					)}
					{!isValidName && (
						<div
							className={`validation-message ${
								name.length === 0 ? 'hidden' : ''
							}`}
						>
							введено некоректне ім`я
						</div>
					)}
				</div>
				<div className='item-cost__input-cost input-cost'>
					<span className='input-cost__icon'>
						<img src='img/svg/cost/phone.svg' alt='Phone' />
					</span>
					<PhoneInput
						placeholder='+ 3 8 (__) ___ __ __'
						country={'ua'}
						value={phone}
						onChange={handlePhoneChange}
						className='input-cost__input'
					/>
					{isValidPhone ? (
						<span
							className={`input-cost__validation-icon ${
								phone.length > 3 ? '' : 'hidden'
							}`}
						>
							<img src='img/svg/cost/true.svg' alt='True' />
						</span>
					) : (
						<span
							className={`input-cost__validation-icon ${
								phone.length > 3 ? '' : 'hidden'
							}`}
						>
							<img src='img/svg/cost/false.svg' alt='False' />
						</span>
					)}
					{!isValidPhone && (
						<div
							className={`validation-message ${
								phone.length > 3 ? '' : 'hidden'
							}`}
						>
							введено некоректний номер телефону
						</div>
					)}
				</div>
				<button
					disabled={!isValidName || !isValidPhone}
					className={`cost__button cost__button-finish text ${
						!isValidName || !isValidPhone ? 'unactive' : ''
					}`}
					onClick={handleSubmit}
				>
					Готово
				</button>
			</div>
		);
	}

	if (step === 4) {
		bodyContent = (
			<div className='cost__finish finish-cost'>
				<div className='finish-cost__content'>
					<div className='finish-cost__title title'>
						Ваші дані успішно відправлено
					</div>
					<div className='finish-cost__text text'>
						Очійкуте дзвінка спеціаліста найближчим часом.
					</div>
					<div className='finish-cost__icon'>
						<img src='img/svg/cost/finish.svg' alt='Finish' />
					</div>
				</div>
			</div>
		);
	}

	return (
		<section
			id='cost'
			className={`cost ${step === 0 ? '' : 'gray'} ${
				step === 4 ? 'center' : ''
			}`}
		>
			<div className='cost__container'>{bodyContent}</div>
		</section>
	);
};

export default Cost;
