import React from 'react';

const WorkItem = ({ number, imageSrc, text }) => {
	return (
		<div className='work__item item-work'>
			<div className='item-work__number number'>{number}</div>
			<div className='item-work__body'>
				<div className='item-work__icon'>
					<img src={imageSrc} alt={imageSrc} />
				</div>
				<div className='item-work__text text'>{text}</div>
			</div>
		</div>
	);
};

export default WorkItem;
