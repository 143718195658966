import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import PhoneInput from 'react-phone-input-2';

import axios from 'axios';

const Hero = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false);

	const [step, setStep] = useState(0);
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [isValidName, setIsValidName] = useState(false);
	const [isValidPhone, setIsValidPhone] = useState(false);

	useEffect(() => {
		if (modalIsOpen) {
			document.documentElement.classList.add('modal-open');
		} else {
			document.documentElement.classList.remove('modal-open');
		}
	}, [modalIsOpen]);

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
		setIsValidName(false);
		setIsValidPhone(false);
		setName('');
		setPhone('');
		setStep(0);
	};

	const handleNameChange = (event) => {
		const newName = event.target.value;
		setName(newName);

		setIsValidName(newName.length > 1);
	};

	const handlePhoneChange = (value) => {
		setPhone(value);

		setIsValidPhone(value.length === 12);
	};

	const handleSubmit = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/hero`, { name, phone })
			.then((response) => {
				if (response.data.success) {
					setStep((value) => value + 1);
				} else {
					console.error(
						'Произошла ошибка при отправке данных в телеграм-бота'
					);
				}
			})
			.catch((error) => {
				console.error(
					'Произошла ошибка при отправке данных в серверную часть',
					error
				);
			});
	};

	let bodyContent = (
		<div className='hero-modal__body'>
			<div className='hero-modal__text'>Залиште свої контактні дані</div>
			<div className='hero-modal__inputs'>
				<div className='hero-modal__input input-cost'>
					<span className='input-cost__icon'>
						<img src='img/svg/cost/person.svg' alt='Person' />
					</span>
					<input
						type='text'
						placeholder='Ваше ім`я'
						value={name}
						onChange={handleNameChange}
						className='input-cost__input hero-modal__input'
					/>
					{isValidName ? (
						<span
							className={`input-cost__validation-icon ${
								name.length === 0 ? 'hidden' : ''
							}`}
						>
							<img src='img/svg/cost/true.svg' alt='True' />
						</span>
					) : (
						<span
							className={`input-cost__validation-icon ${
								name.length === 0 ? 'hidden' : ''
							}`}
						>
							<img src='img/svg/cost/false.svg' alt='False' />
						</span>
					)}
				</div>
				<div className='hero-modal__input input-cost'>
					<span className='input-cost__icon'>
						<img src='img/svg/cost/phone.svg' alt='Phone' />
					</span>
					<PhoneInput
						placeholder='+ 3 8 (__) ___ __ __'
						country={'ua'}
						value={phone}
						onChange={handlePhoneChange}
						className='input-cost__input hero-modal__input'
					/>
					{isValidPhone ? (
						<span
							className={`input-cost__validation-icon ${
								phone.length > 3 ? '' : 'hidden'
							}`}
						>
							<img src='img/svg/cost/true.svg' alt='True' />
						</span>
					) : (
						<span
							className={`input-cost__validation-icon ${
								phone.length > 3 ? '' : 'hidden'
							}`}
						>
							<img src='img/svg/cost/false.svg' alt='False' />
						</span>
					)}
				</div>
			</div>
			<button
				disabled={!isValidName || !isValidPhone}
				className={`hero-modal__button ${
					!isValidName || !isValidPhone ? 'unactive' : ''
				}`}
				onClick={handleSubmit}
			>
				Готово
			</button>
			<div className='hero-modal__cancel' onClick={closeModal}>
				<img src='img/svg/cancel.svg' alt='Cancel' />
			</div>
		</div>
	);

	if (step === 1) {
		bodyContent = (
			<div className='hero-modal__body hero-modal__body-finish'>
				<div className='hero-modal__content'>
					<div className='hero-modal__title'>
						Ваші дані успішно відправлено
					</div>
					<div className='hero-modal__text hero-modal__text-finish'>
						Очійкуте дзвінка спеціаліста найближчим часом.
					</div>
				</div>
				<button
					disabled={!isValidName || !isValidPhone}
					className={`hero-modal__button ${
						!isValidName || !isValidPhone ? 'unactive' : ''
					}`}
					onClick={closeModal}
				>
					Готово
				</button>
				<div className='hero-modal__cancel' onClick={closeModal}>
					<img src='img/svg/cancel.svg' alt='Cancel' />
				</div>
			</div>
		);
	}

	return (
		<section id='hero' className='hero'>
			<div className='hero__container'>
				<div className='hero__body'>
					<div className='hero__content'>
						<div className='hero__title'>
							Комплексний ремонт під ключ
						</div>
						<div className='hero__text'>
							Перетворимо житловий простір <br /> на досконалість
							завдяки якості роботи.
						</div>
					</div>
					<button className='button' onClick={openModal}>
						<div className='button__text'>Лишити заявку</div>
					</button>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				contentLabel='Hero Modal'
				className='hero-modal'
				overlayClassName='hero-modal__overlay'
			>
				{bodyContent}
			</Modal>
		</section>
	);
};

export default Hero;
