import React from 'react';

import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Work from './components/Work';
import Advantages from './components/Advantages';
import Portfolio from './components/Portfolio';
import Cost from './components/Cost';
import Footer from './components/Footer';

function App() {
	return (
		<main className='App'>
			<div className='wrapper'>
				<div className='overlay'></div>
				<Header />
				<Hero />
				<About />
				<Work />
				<Advantages />
				<Portfolio />
				<Cost />
				<Footer />
			</div>
		</main>
	);
}

export default App;
